import { Box, Image } from '@chakra-ui/react';
import type { FC } from 'react';
import caduceus from './caduceus.svg';
import clipboard from './clipboard.svg';
import devices from './devices.svg';
import globe from './globe.svg';
import reflect from './reflect.svg';
import stars from './stars.svg';

const contents = [
  {
    title: 'Evidence-based',
    text: 'Developed by mental health experts.',
    imageProps: { src: caduceus },
    width: '340px',
  },
  {
    title: 'On all your devices',
    text: 'Get support no matter which device is near by.',
    imageProps: { src: devices },
  },
  {
    title: 'Empowering insights',
    text: 'Understand how your thought patterns change over time.',
    imageProps: { src: reflect },
    width: '400px',
  },
  {
    title: 'Track your wellbeing',
    text: 'Stay on top of anxiety and depression levels with weekly assessments.',
    imageProps: { src: clipboard },
  },
  {
    title: 'Global',
    text: 'Available in 177+ countries.',
    imageProps: { src: globe },
    width: '300px',
  },
  {
    title: '2,600+ people',
    text: 'Who have seen a clinically important improvement in their mental health.',
    imageProps: { src: stars },
  },
];

const ValuePropCards: FC = () => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center" gap="regular">
      {contents.map(({ title, text, imageProps, width }, index) => (
        <Box
          key="title"
          borderColor="brand.2"
          borderWidth="1px"
          borderRadius="16px"
          p="deka"
          display="inline-flex"
          color="brand.2"
          gap="regular"
          maxWidth="380px"
          width={{ base: '100%', md: width }}
          bg={index % 2 === 0 ? 'transparent' : 'brand.bg.10'}
        >
          <Box>
            <Image alt="" {...imageProps} />
          </Box>
          <Box flex={1}>
            <Box textStyle={{ base: 'popGoose', lg: 'popSwan' }}>{title}</Box>
            <Box>{text}</Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ValuePropCards;
